import 'whatwg-fetch';
import { h, render, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
    TextField,
    SelectField,
    StandardForm,
    useForm,
    get_data,
    v,
    f,
    opts,
    json_to_urlencoded,
} from '@xmlleads/form-magic';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Modal from 'react-modal';

Modal.setAppElement('#login-container');

const Forgot = ({ set_show_forgot_password }) => {
    const [forgot_form, update_forgot_form] = useForm({});
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [processing, set_processing] = useState(false);
    const schema = {
        email: v.required,
        state: v.required,
    };

    const get_csrf = async () => {
        const response = await fetch('/form/get-csrf');
        if (!response.ok) {
            throw 'Non-200 on CSRF fetch';
        }
        const data = await response.json();
        return data;
    };

    const handle_recaptcha = async (action) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha(action);
        return token;
    };

    const forgot_submit = async () => {
        set_processing(true);
        const data = get_data(forgot_form);
        const errors = {};
        const key = [];
        Object.keys(schema).map((i) => {
            const result = schema[i](data[i]);
            if (result) {
                key.push(i);
                errors[i] = result;
            }
        });
        if (key.length > 0) {
            update_forgot_form({ key, error: errors, type: 'show_error' });
            set_processing(false);
            return;
        }
        const csrf = await get_csrf();
        data[csrf.token_name] = csrf.token_value;
        const captcha = await handle_recaptcha('forgot');
        data['captcha'] = captcha;
        const res = await fetch('/login/forgot/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: json_to_urlencoded(data),
        });
        const response = await res.json();
        set_processing(false);
        alert('You should get an email with further instructions.');
    };

    const child_modal_style = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
        },
    };

    return (
        <Fragment>
            <Modal isOpen={processing} style={child_modal_style}>
                <h1>Processing, please wait...</h1>
            </Modal>
            <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
                Recover Your Account
            </h1>
            <div className="mt-8">
                <div className="mt-6">
                    <StandardForm
                        className="space-y-6"
                        schema={schema}
                        form={forgot_form}
                        update={update_forgot_form}
                        id="login-form"
                        submit={forgot_submit}
                        show_application_error={() => {}}
                    >
                        <TextField label="Email:" name="email" />
                        <SelectField
                            name="state"
                            autofocus
                            label="State in which you Reside:"
                            default_label="-- State --"
                            options={opts.states}
                        />
                    </StandardForm>
                </div>
            </div>
        </Fragment>
    );
};

export default Forgot;
