import 'whatwg-fetch';
import { h, render, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
    TextField,
    SelectField,
    StandardForm,
    useForm,
    get_data,
    v,
    f,
    opts,
    json_to_urlencoded,
} from '@xmlleads/form-magic';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Login from 'js/components/Forms/Login.js';
import Forgot from 'js/components/Forms/Forgot.js';

const LoginPage = () => {
    const [show_forgot_password, set_show_forgot_password] = useState(false);

    return (
        <main id="login">
            <GoogleReCaptchaProvider reCaptchaKey="6LdlivMhAAAAAFsV1fPGU_6_lCyXPVQXo12SdVsn">
                {!show_forgot_password && (
                    <Login
                        set_show_forgot_password={set_show_forgot_password}
                    />
                )}
                {show_forgot_password && (
                    <Forgot
                        set_show_forgot_password={set_show_forgot_password}
                    />
                )}
            </GoogleReCaptchaProvider>
        </main>
    );
};

render(<LoginPage />, document.getElementById('login-container'));
